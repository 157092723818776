.opa-table {
    tbody tr{
        height: 56px;
    }
    tbody tr td:nth-child(2) {
        font-weight: 900;
    }
    thead tr th:nth-child(3){
        display: flex;
        justify-content: start;
        align-items: center;
        min-width: 220px;
    }
    tbody tr td:nth-child(4) p{
        color:#444444;
        margin-left: 4px;
    }
    thead tr th:nth-child(5){
        .arrows-container{
            display: none;
        }
    }
    thead tr th:nth-child(2){
        min-width: 170px;
    }
}
@media screen and (max-width: 1024px) {
    .opa-table thead tr th:nth-child(2), .opa-table thead tr th:nth-child(3) {
        display: none;
    }
    .opa-table thead tr th:nth-child(1), .opa-table thead tr th:nth-child(4) {
        padding-right: 12px;
        padding-left: 12px;
    }
    .opa-table tbody tr td:nth-child(1) {
        padding-right: 12px;
        padding-left: 12px;
    }
    .opa-table tbody tr td:nth-child(4) {
        padding-right: 0;
        padding-left: 0;
    }
    .opa-table tbody tr td:nth-child(4) p{
        width: 94px;
    }
    .opa-table tbody tr td:nth-child(5) {
        padding-right: 8px;
        padding-left: 0;

    }

    .opa-table tbody tr td:nth-child(2), .opa-table tbody tr td:nth-child(3) {
        display: none;
    }
    .opa-table tbody tr td:nth-child(4) {
        width: 40px !important;
        min-width: unset !important;
    }
    .opa-table tbody tr td:nth-child(5) {
       min-width: unset;
        width: fit-content;
    }
}
