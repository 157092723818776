.notification {
    max-width: 430px;
    overflow: hidden;
    padding: 16px 44px 16px 16px;
    z-index: 99;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 24px;
    font-weight: bold;
    position: relative;
    transition: transform 0.3s ease-out;
}

.notification.slideIn {
    animation-name: slideIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
}

.notification.slideOut {
    transform: translateX(150%);
    flex: 0;
}

.notification:not(:last-child) {
    margin-bottom: 8px;
}

.notification.info {
    background-color: #2196f3;
}

.notification.success {
    background-color: #4caf50;
}

.notification.warning {
    background-color: #ff9800;
}

.notification.error {
    background-color: #f44336;
}

.notification .closeButton {
    position: absolute;
    top: 14px;
    right: 16px;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
}

.notification,
.notification .closeButton {
    color: #fff;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

.notification-container {
    overflow: hidden;
    max-height: 200px;
    transition: max-height 0.3s ease-out;
}

.notification-container:not(:last-child) {
    margin-bottom: 8px;
}

.notification-container.shrink {
    max-height: 0;
}
.notification-container {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 100;
}