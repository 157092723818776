.client-table thead th:last-child .arrows-container{
    display: none;
}
.client-table tbody tr td:nth-child(3){
    @media screen and (min-width: 590px) {
        text-align: end;
    }

}
.client-table td{
    font-size: 14px;
}
.client-table th{
    height: 54px;
}
.client-table tbody tr td:nth-child(2){
    padding-right:0 !important;
    @media screen and (min-width: 590px) {
        padding-right: 24px !important;
    }
    
}
.client-table tbody tr td{
    padding-right: 2px !important;
    padding-left: 18px !important;
    @media screen and (min-width: 590px) {
        padding-right: 24px !important;
        padding-left: 24px !important;
    }
}
/*mobile*/
@media screen and (max-width: 1024px) {
    .client-table thead tr th:nth-child(2),.client-table thead tr th:nth-child(3){
        display: none;
    }
    .client-table tbody tr td:nth-child(2){
        display: none;
    }
    .client-table tbody tr td:nth-child(3){
        font-size: 12px;
        text-align: end;
        padding-right: 24px !important;
    }
}