@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        height: 100%;
        margin: 0;
        padding: 0;

    }

    * {  font-family: 'Lato', sans-serif; }

    body {


        font-family: 'Lato', sans-serif;

        max-width: 100%;
        overflow-x: hidden;

        margin: 0;
        padding: 0;
        height: 100%;




    }
    @media screen and (min-width: 768px ){
        body{
            background-color: #f2f8ff;
        }

    }

    .root {
        height: 100%;



    }
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        src: url('assets/fonts/lato-v24-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }

    @font-face {
        font-family: 'icomoon';
        src:url('../src/assets/fonts/icomoon.eot?b8t0ed');
        src:  url('../src/assets/fonts/icomoon.eot?b8t0ed');
        src:  url('../src/assets/fonts/icomoon.eot?b8t0ed#iefix') format('embedded-opentype'),
        url('../src/assets/fonts/icomoon.ttf?b8t0ed') format('truetype'),
        url('../src/assets/fonts/icomoon.woff?b8t0ed') format('woff'),
        url('../src/assets/fonts/icomoon.svg?b8t0ed#icomoon') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }
    /* lato-regular - latin */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        src: url('assets/fonts/lato-v24-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* lato-700 - latin */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        src: url('assets/fonts/lato-v24-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
    /* lato-900 - latin */
    @font-face {
        font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        src: url('assets/fonts/lato-v24-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
}



@layer components {
    /*buttons*/
    .btn {
        @apply rounded-sm tracking-[1px] uppercase p-2;
    }

    .btn-primary {
        @apply bg-ere-green text-ere-dark-blue border-solid border-2 border-ere-green font-bold;
    }

    .btn-primary-active-and-hover {
        @apply text-ere-green bg-opacity-0 bg-transparent border-2 border-ere-green;
    }

    .btn-secondary {
        @apply bg-ere-link-blue text-white border-solid border-2 border-ere-link-blue font-black py-[4px] px-[10px] rounded-[2px];
    }

    .btn-secondary-active-and-hover {
        @apply text-ere-link-blue bg-opacity-0 bg-transparent border-2 border-ere-link-blue font-black py-[4px] px-[10px]  rounded-[2px];
    }

    .btn-disabled {
        @apply bg-ere-light-gray text-white font-bold;
    }

    .btn-disabled-active-and-hover {
        @apply bg-ere-dark-gray;
    }

    .btn-cta {
        @apply bg-ere-orange text-white border-solid border-2 border-ere-orange font-bold text-sm;
    }

    .btn-cta-active-and-hover {
        @apply text-ere-orange bg-opacity-0 bg-transparent border-2 border-ere-orange;

    }

    .link-hover {
        @apply text-ere-link-blue cursor-pointer;
    }

    /*universal*/
    .light-blue-border {
        @apply rounded-[4px] border-[2px] border-ere-link-blue border-opacity-40;
    }
    .light-blue-border-category {
        @apply rounded-[2px] border-[2px] border-ere-link-blue border-opacity-40;
    }
    .btn-cta-active-and-hover > svg * {
        fill:#F57655;
    }
    .btn-cta > svg * {
        fill: #FFFF;
    }
    .btn-secondary > svg * {
        fill: #FFFF;
    }



}
@layer utilities{
    [class^="icon-"], [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .icon-check-selected .path1:before {
        content: "\e910";
        color: rgb(5, 102, 136);
    }
    .icon-check-selected .path2:before {
        content: "\e911";
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
    .icon-collapse-sidebar .path1:before {
        content: "\e912";
        color: rgb(241, 248, 255);
    }
    .icon-collapse-sidebar .path2:before {
        content: "\e913";
        margin-left: -1em;
        color: rgb(0, 148, 216);
    }
    .icon-done-status:before {
        content: "\e914";
    }
    .icon-grid:before {
        content: "\e915";
        color: #0094d8;
    }
    .icon-ja:before {
        content: "\e916";
        color: #0094d8;
    }
    .icon-list:before {
        content: "\e917";
        color: #999;
    }
    .icon-nein:before {
        content: "\e918";
        color: #0094d8;
    }
    .icon-process:before {
        content: "\e919";
        color: #0094d8;
    }
    .icon-sonstiges:before {
        content: "\e91a";
        color: #0094d8;
    }
    .icon-aufgaben:before {
        content: "\e90a";
        color: #777;
    }
    .icon-basisdatenschutz:before {
        content: "\e90b";
        color: #777;
    }
    .icon-dashboard:before {
        content: "\e90c";
        color: #777;
    }
    .icon-dokumente:before {
        content: "\e90d";
        color: #777;
    }
    .icon-einstellungen:before {
        content: "\e90e";
        color: #777;
    }
    .icon-erweiterter-schutz:before {
        content: "\e90f";
        color: #777;
    }
    .icon-arrow-right:before {
        content: "\e900";
        color: #0094d8;
    }
    .icon-attention-red:before {
        content: "\e901";
        color: #ff675b;
    }
    .icon-check:before {
        content: "\e902";
        color: #fff;
    }
    .icon-chevron-down:before {
        content: "\e903";
        color: #777;
    }
    .icon-chevron-left:before {
        content: "\e904";
        color: #0094d8;
    }
    .icon-chevron-right:before {
        content: "\e905";
        color: #0094d8;
    }
    .icon-delete:before {
        content: "\e906";
        color: #0094d8;
    }
    .icon-edit:before {
        content: "\e907";
        color: #0094d8;
    }
    .icon-info:before {
        content: "\e908";
        color: #056688;
    }
    .icon-plus-circle:before {
        content: "\e909";
        color: #0094d8;
    }

    /*custom css*/
    /*icons*/
    .active-blue {
        color: blue;
    }

    .icon-attention-white:before {
        content: "\e901";
        color: white;
    }
    .icon-collapse-sidebar{
        filter: drop-shadow(4px 4px 6px rgba(0, 19, 30, 0.10));
    }
    .tooltip {
        @apply invisible absolute;
    }

    .has-tooltip:hover .tooltip {
        @apply visible z-50;
    }
    .gray-info-icon::before{
        color: #777777;
    }
    .btn-secondary-active-and-hover:hover {
        .icon:before{

        color: white;
    }  }


    /*white info incon on answer*/
    .white-info-icon path{
        fill:white;
    }

    .peer:checked ~ .peer-checked\:bg-ere-checkbox-blue {

        > div{
            padding-left: 40px !important;
            transition: padding 300ms ease-in-out;
           padding-right: 10px;
        }
        & svg path{
            fill: white;
        }

        .react-tooltip-arrow{
            left: 49% !important;
            top: 5px !important;
            z-index: -1 !important;

        }
    }}
.has-tooltip {
padding-right: 1.5rem;
}
.react-tooltip{
    opacity:1 !important;

    padding: 20px !important;

    box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.15);
    color: #444444 !important;
    font-size: 13px !important;

    /*filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.25)) !important;*/


}
/*notification-grid*/
.notification-grid{
    grid-template-columns: 0fr 10fr 0fr ;
}

/*avoid check icon in mobile*/
.box_layout_yes_no label{
width: fit-content !important;
}
.show-desc{
   overflow-x:hidden;
    display: flex;
    transition: all ease-in-out 500ms;
    width: 25px;
    &:hover{
        overflow-x:visible;
        width: fit-content;
        transition: all ease-in-out 500ms;
    }
    &:hover::after{


        display: inline;
        transition: all ease-in-out 500ms;
        width: fit-content;
    }
}
.show-desc::after{

    transition: all ease-in-out 500ms;
width: 0;

        content: "Neu erstellen";

        height: 20px;
    }
.with-action{

    display: inline-block !important;
}
/*filter*/

.selectcountry{
   /* background-image: url("../src/assets/images/selectdown.png");
*/
    background: url("../src/assets/images/selectdown.png") no-repeat right white;
    -webkit-appearance: none;
    background-position-x: 99%;
}

/*modal card text*/
.modal-card h1, .modal-card h2{
    font-size: 20px;
    color:#004059;
}

/*slick-carousel*/
.slick-arrow::before{
    display: none;
}
.slick-prev {
    left: -21px !important;
    font-size: 20px !important;
}
.slick-next {
    right: -21px !important;
    font-size: 20px !important;

}
@media screen and (min-width: 590px) {
    .slick-prev{
        top: -35px !important;
        right: 20px !important;
        left:unset !important;
    }
    .slick-next{
        top: -35px !important;
        right: 0 !important;
        left:unset !important;
    }
}


.slick-list > .slick-track{
    /*width:300px !important;*/
   /* margin-right: 24px;*/
   /* margin-left: 0 !important;*/
}

.slick-track{
    margin-left:unset !important;
    display:flex;
}

/*dashboard*/
.dashboard-task-list li:last-child{
    margin-bottom: 0;
}

@media screen and (min-width: 590px) {
  /*  .slick-track{
        margin: 0 -24px;

    }*/
    .slick-track .slick-current > div{
        margin-right: 15px;
        padding-right:3px !important;

    }

}
@media screen and (min-width: 790px) {

    .slick-track .slick-current  > div{
        margin-right: 15px;

    }
    .slick-track .slick-current + .slick-slide > div{
        margin-right: 15px;
        padding-right:3px !important;

    }

}

.slick-active > div{
    margin-right:2px;
}
/*arrows disabled*/
.slick-disabled svg g > *{
    stroke: #ddd !important;
}
.slick-disabled{
    cursor: default !important;
}

.rte-field a {
    color: #0094d8;
    text-decoration: underline;
}
/*bug resolved: self growing width on parent element*/
/*
.slick-slider, .slick-list{
    max-width: 960px;
}*/

/*list element answers break word this class removed bc bad word breaks
.break-word-class{
    word-break: break-word;
}*/
/*breadcrumb last element aligned
.breadcrumb-wrapper a:has(.font-bold){

    font-size: 13.7px;
    margin-bottom: 2px;
}

.breadcrumb-container a:last-child{
    margin-bottom: 4px;
    color: red;
}*/
/*contract table*/

/*doesn't work in shadow dom
[data-tour-elem="left-arrow"] button{
    color:red !important;
}
.gnFBec{
    color:red !important;
}*/

/*dashboard mobile view tasks divider*/
.has-divider{
    @media screen and (min-width: 590px){
        &:after{display: none;}
    }
}
.has-divider:not(:last-of-type):after{
    content: "";
    position: absolute;
    margin: auto;

    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E0E5EB;

}

/*risk images*/
.risk-img{
    width: 105px;
    height: 33px;
    display:block;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
}
.img-red{
    background-image:url("../src/assets/images/risk/red.png");
}
.img-orange{
    background-image:url("../src/assets/images/risk/orange.png");
}
.img-yellow{
    background-image:url("../src/assets/images/risk/yellow.png");
}
.img-lightgreen{
    background-image:url("../src/assets/images/risk/lightgreen.png");
}
.img-darkgreen{
    background-image:url("../src/assets/images/risk/darkgreen.png");
}
.risk-level-container span:nth-child(2){

    width: 946px;
}

