/*tasks table*/
.tasks-table thead tr th:nth-child(2),.tasks-table thead tr th:nth-child(4){
    padding-left: 0;
    margin-right: 25px;
    min-width: 50px;
}

.tasks-table thead tr th:nth-child(3)
{
    padding-left: 0;
    padding-right: 0;
    min-width: 120px;
}
.tasks-table tbody tr{

    height: 49px !important;
    padding-left: 24px;




}
.tasks-table tbody tr td:first-child{
    padding-left: 24px;



}
.tasks-table .arrows-container{
    display: flex;
}
.tasks-table .arrows-container svg{
    display: inline-flex;
}
.tasks-table thead th:last-child .arrows-container{
    display: none;
}
.tasks-table thead th > div {
    padding-top: 10px;


}
.tasks-table thead th:last-child > div {
    padding-top: 0;

}
/*.tasks-table thead th > *{
   display: inline-flex;

}*/
.tasks-table thead th div:nth-child(2){
    padding-bottom: 5px;

}
.tasks-table tbody tr td:nth-child(2){
    font-weight: bold;
}
.tasks-table tbody tr td:first-child{
    width: 50%;
}


/*pagination*/
.page-item .active{


    background-color: #004059;
    color:white;
}
.to-left::after{
    content: '\003C';
    display: block;
}
/*remove select on first and last when search is used*/
.tasks-table thead tr th:first-child select{
    display: none;
}
.tasks-table thead tr th:last-child select{
    display: none;
}
.tasks-table select{

    height: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F8FF;
}
.tasks-table select:focus{
    outline: none;
}
.tasks-table thead th:nth-child(2) select{
    width: 100%;
}

.tasks-table thead{
    background-color: #F1F8FF;
}
/*status .tasks-table header*/
/*status .tasks-table header*/
/*.tasks-table thead tr th:nth-child(2){
    display: flex;
    justify-content: space-around;
    height: 90px;
    padding-top: 18px;


    & select {
        display: block;
        width: 130px;
        left:-110px;

    }
}*/
/*!*status*!
.tasks-table thead tr th:nth-child(2) div:nth-child(3) div div{
    width: 135px;
    & select{
        width: 60%;
    }
}
.tasks-table thead tr th:nth-child(2){
    width: 10%;
    padding-right:5px;
    padding-top:35px;
}

!*risiko*!
.tasks-table thead tr th:nth-child(3) div:nth-child(3) div div{
    width: 200px;
    & select{
        width: 110%;
    }
}
.tasks-table thead tr th:nth-child(3){
    width: 10%;
    padding-right:0;
    padding-top:35px;
}*/
.tasks-table tbody tr td{
    height: 100%;
    padding-top:10px;
    padding-bottom:10px;
    &:first-child{
        padding-right: 5px;
    }

}
.tasks-table thead tr th:nth-child(3){
    width: 15%;
}
@media screen and (min-width: 768px) {
    .tasks-table thead tr th:nth-child(2){
        width: 18%;
    }
    .tasks-table thead tr th:nth-child(3){
        width: 18%;

    }
    .tasks-table thead tr th:nth-child(3){
        width: 17%;
    }
}


.tasks-table-border{
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 2px;
}
.tasks-table tbody{
    color:#444444;
}
/*padding right table 24px*/
.tasks-table tbody tr td:nth-child(3){
    text-align: start;
    padding-right: 14px;

}
.tasks-table tbody tr td:nth-child(4){

    padding-right: 20px;
    width: 12%;

}
.tasks-table thead tr th:nth-child(2) {
    padding-right: 0;
}
/*mobile*/
@media screen and (max-width: 768px) {
    .tasks-table tbody tr td:nth-child(3) {
        padding-right: 10px;
        font-size: 12px !important;
    }

    .tasks-table tbody tr td:nth-child(1) {
        padding-left: 12px;
    }

    .tasks-table tbody tr td:nth-child(2) {
        display: table-cell;
        align-items: center;
        min-width: 20px !important;
    }

    .tasks-table tbody tr td:nth-child(3) {
        text-align: start;



    }

    .tasks-table thead tr th:nth-child(1) {
        padding-right: 0;

    }
    .tasks-table thead tr th:nth-child(2) {
        padding-right: 0;
    }

    .tasks-table thead tr th:nth-child(3) {
       padding-left: 24px;
    }
    .tasks-table tbody tr td:nth-child(3) {
       padding-left: 34px;
    }
    .tasks-table thead tr th:nth-child(2) {
        padding-left: 14px;
    }
    .tasks-table tbody tr td:nth-child(2) {
       padding-left: 14px;
    }
    .tasks-table thead tr th:nth-child(1) {
        height:50px;
        display: flex;
        align-items: center;
        padding-left:14px;
    }
    .tasks-table thead tr th:nth-child(2) {
       min-width: 115px;
    }
}
@media screen and (max-width: 1024px) {
    .tasks-table tbody tr td:nth-child(4){
        padding-right: 5px;
        min-width: 10px;

    }
}
@media screen and (min-width: 1024px) {
    .tasks-table thead tr th:nth-child(3) {
        padding-left: 14px;
    }
    .tasks-table tbody tr td:nth-child(3) {
        padding-left: 14px;
    }
}
