

/*bottom nav light, body must be white*/
.bottom-nav-light {
    background-color: #f2f8ff;
    color: #999999;

    .icon:before {
        width: 24px;
        height: 24px;
        color: #999999;
    }
}
.bottom-nav span {
    color: #999999;
}

.active-blue .icon:before, .active-blue span {
    color: #0596d9;

}
.icon{
    padding-bottom: 4px;
}
/*
.gray svg *{
    fill:#777777;
}
.gray{

    fill:#777777;


}

*/



/*footer*/
.bottom-nav span{
    color:#1F719F;
}
.bottom-nav .icon{
    padding-bottom: 4px;

}
.bottom-nav .blue svg *{
    fill:#1F719F;
}
.bottom-nav .blue{

    fill:#777777;
}
.bottom-nav .active > span{
    color: white;
}
.bottom-nav .active svg *{
    fill: white;

}
.bottom-nav .active-menu span{
    color: white;
}

/*when 1 submenu active, unternehmen also active*/
.bottom-nav .has-submenu .mobile-submenu:has(.active-menu)
{
   > span {
        color: white;
    }
   svg *{
        fill: white;
    }

}
.has-submenu:has(.mobile-submenu .active-menu){
    svg * {
        fill: white !important;
    }
    > span{
        color: white !important;
    }
}
/*datenschutzbeauftragter -> active unternehmen and icon*/
.has-to-be-white *, .has-to-be-white + span{
    fill: white !important;
    color:white;
}
/*datenschutzbeauftragter -> active ustammdaten*/
.has-to-be-white ~ div  a:first-child  span{
    color: white !important;
}