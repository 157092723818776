.skipped-questions-table{

    .toggle-dropdown{
        cursor: pointer;
    }
    .toggle-dropdown{
        .dropdown-menu{

        }
        .sort-icon{
            transition: all 300ms ease-out;
        }
    }
    .toggle-dropdown:has(.category-text.hide){

        .dropdown-menu{
            border:none;
            margin-top:0;
            margin-bottom: 10px;
        }
        .sort-icon{

            rotate: 180deg;

        }
        .dropdown-item{
            display: none;

        }
    }

    tbody tr td:nth-child(2){
        border-bottom: none;
        display: flex;
        align-items: start;
        justify-content: end;
        padding-right: 24px;
        @media screen and (max-width: 768px){
        span{
            justify-content: end;
        ;
        }

        }


    }
    thead tr th:nth-child(2) {
       width: 15%;
        text-align: right;
        padding-right: 24px;
        @media screen and (max-width: 768px) {
            text-align: right;
            padding-right: 24px;
        }


        .arrows-container{
            display: flex;
        }
        > div {
            padding-top: 10PX;
        }
   }
    thead tr th:nth-child(1) {
        div{
           padding-top:unset;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
        }
        .arrows-container{
            display: none;
        }
    }



    }