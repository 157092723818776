@media screen and (max-width: 768px) {

    .documents-table  thead tr th:nth-child(2),.documents-table  thead tr th:nth-child(3){
        display: none;
    }
    .documents-table  tbody tr:first-child td:nth-child(2),.documents-table  tbody tr:first-child td:nth-child(3){
        display: none;
    }
    .documents-table  tbody .special-row td:nth-child(4){
        width: fit-content;
    }

    .documents-table  .special-row{

    }
    .documents-table  .special-row td:nth-child(2),.documents-table  .special-row td:nth-child(3){
        display: none;
    }
    .documents-table  tbody tr:not(.special-row) td:nth-child(1),.documents-table  tbody tr:not(.special-row) td:nth-child(2){
        display: none;
    }
    .documents-table  tbody tr:not(.special-row) td:nth-child(3){
        padding-left: 20px;
    }

}