
@media screen and (max-width: 768px) {
    .contracts-table thead tr th:nth-child(2),.contracts-table thead tr th:nth-child(3){
        display: none;
    }
    .contracts-table tbody tr td:nth-child(2), .contracts-table tbody tr td:nth-child(3){
        display: none;
    }
    .contracts-table tbody tr td:nth-child(4){
        width: fit-content;
    }
}