.progress-bar {
    /*background: linear-gradient(87deg, #3dc6ef, #0094d8, #19516f);*/
    background: linear-gradient(86deg, #3DC6EF 48.74%, #0094D8 87.06%);
   /* background-size: 180% 180%;*/
    /*animation: colorchange 6s ease infinite;*/
}
/*

.progress-bar-100 {
    background: linear-gradient(86deg, #66E6BF, #44C69F, #349477);
    background-size: 180% 180%;
    animation: colorchange 10s ease infinite;
}
*/

@keyframes colorchange {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.icon-process:before{
    position:relative;
    right:22px;
}
.icon-process-done:before{
   color:#44C69F;
}