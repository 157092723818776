.project-table{
    input[type="radio"]{
        appearance: none;
        border: 1px solid #0094D8;
        max-width: 14px;
        height: 14px;
        content: none;
        outline: none;
        margin: 0;
        background-color: white;
        border-radius: 2px;

    }

    input[type="radio"]:checked{
        appearance: none;
        outline: none;
        padding: 0;
        content: none;
        border: none;
        margin-right: 10px;

        & + .my-check{
            background-color:#0094D8;
            fill:white;
            position: absolute;
            width: 14px;
            height: 12px;
        }


    }

    input[type="radio"]:checked::before{
        width: 14px;
        height: 14px;
        position: absolute;
        color: #0094D8 !important;
        content: "" !important;
        border: 1px solid #0094D8;
        font-weight: bolder;
        font-size: 15px;
        border-radius: 2px;


    }
}
.project-table thead th:nth-child(2){
    display: flex;
    justify-content: start;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .project-table th, .project-table td{
        padding-right: 12px;
        padding-left: 12px;
    }
}