
.level-0:hover{

    cursor: pointer;

}
.icon{
    font-size:24px;
}
.icon-chevron-down:before{
    font-size: 12px;
}



.level-0 a:hover{
    color:#0094d8;


}
.level-0 a:hover span{
    color: #0094d8;
}


/*.active{
    color: #0094d8;
    .icon{
        color: #0094d8;
    }
    .icon:before{
        color: #0094d8;
    }
    .icon-chevron-down::before{
        color: #0094d8;
    }
}*/

.btn-cta:hover {
    .icon-attention-white::before {
        color: red;
    }

}
.bigdist{
    padding-left: 20.4px;

}
.smalldist{
    padding-left: 18.4px;

}
.level-0{

    height: 49px;

    display:flex;
    align-items: center;
    justify-content: flex-start;
  /*  padding-top: 24px;*/

}
.level-0:hover{
    color:#0094d8;
}

li{
    list-style: none;
}
.sub-menu{

    position: absolute;
    top:100%;
    display: contents;


}
.closed a{
    display: none;
}
.sub-menu .open{
    display: contents;
    height: 58px;

}
.category-name{
    padding-left: 16px;
}

.gray{
    color: #777777;

}
.gray svg:first-child *{
    fill:#777777;
}
.active{
    color: #0094d8;
}
.sidebar-icon{
    width: 24px;
    height: 24px;
    display: inline-block;
}
.active-first-child > span{
    color: #0094d8;

}
/*.arrow-down stop{
    stop-color:#777777;

}*/


/*

.arrow-down-basis #paint0_linear_1058_1567 stop{
    stop-color:#777777;

}
.active + .arrow-down-basis #paint0_linear_1058_1567 stop{
    stop-color:blue;

}
.active + .arrow-down-basis #paint0_linear_1058_1567:nth-child(2){
    stop-color:white;

}

*/


.open a:nth-child(1){
    margin-top:-4px;
}
.open a{
    padding-left: 43px;
    margin-bottom: 9px;
    height:24px;

}
.open a:last-child{

    margin-bottom: 12px;
}

/*hover effect on sidebar*/
.sidebar-wrapper{
    width: fit-content;
    overflow: visible;
}
.sidebar{
    overflow: visible !important;
}
.menu{
    overflow: visible;

}
.menu a{
    position: relative;
    overflow: visible;


    &:hover{
        .sidebar-tooltip{
            display:flex;

        }
        .invisible-wrapper{
            display: inline;
        }
    }
    .sidebar-tooltip{

        left:79px;
        top:5px;
        background-color: white;

        height: fit-content;
        min-height: 40px;
        min-width: 182px;
        position: absolute;
        z-index: 21;


        display: none;
    }
}

.invisible-wrapper{
    position: absolute;

    width: fit-content;
    height: fit-content;
    left:45px;
    overflow: visible;
    z-index:21;
    top:0;
    display: none;
    .sidebar-submenu-right{
        margin-left: 12px;
        height: fit-content;
        .links-wrapper{
            display: inline;
            height: fit-content;
           > a{
                height: 30px;
                color:#777777;
                padding-left:1px;
               margin-bottom: 4px;
                &:hover span{
                    color: #0094d8;
                }
               > span{
                    padding: 0;
                    display: inline;
                    color:#777777;
                }

            }
            > a:last-child{
                margin-bottom: 0;
            }

        }
    }
}
/*right arrow on hover*/
/*.right-icon-hover{
color:#777777 !important;
}
.active .right-icon-hover{
    color:#0094d8 !important;
}
.arrow-right-icon-hover svg{
    all:revert !important;
}*/

/*
.menu-item .arrow-right-icon-hover-basis svg > *{

    all:revert;
}
*/


/*.basis-class:hover{
    .arrow-right-icon-hover-basis{
        display: inline;
    }
}*/
/*
.extended-class:hover svg:nth-child(2) > *{

        display: inline;
        fill:red;
    isolation: isolate;


}
*/
.arrow-top-wrapper{
    position: relative;
    & svg:first-child *{
        fill:white;
    }
}
.overlay-element {
   position: absolute;

    top: 0;
    left: 0;
    width:20px;
    height: 20px;
    background-color: white;
    opacity: 100%;


}

/*last sidebar menu point becomes margin bottom to avoid red button overlaping it*/
#clients{
    margin-bottom: 90px;
}